.checkbox {
  border-radius: 4px;
  width: 21px;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid #bbc1e1;
  background: #fff;
  transition: background 0.3s, border-color 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.checkbox:checked {
  background-color: #0d6efd;
  border: 1px solid #0d6efd;
}

.checkbox:checked + .checkbox-icon svg {
  transform: scale(1) !important;
}

.checkbox-icon {
  position: relative;
  height: 21px;
}

.checkbox-icon .checkbox-icon-svg {
  width: 15px;
  height: 15px;
  position: absolute;
  transition: transform 0.3s;
  color: #fff;
  left: 3px;
  top: 3px;
  pointer-events: none;
}
