.sidebar-inside {
    min-height: calc(100vh - 75px);
    max-width: 250px;
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
    width: 100%;
    background-color: #fff;
    will-change: contents;
    -webkit-animation: sidebar-inside-active 400ms ease-in-out;
            animation: sidebar-inside-active 400ms ease-in-out;
}

@-webkit-keyframes sidebar-inside-active {
    from {
        transform: translateX(-100%);
    }
  
    to {
        transform: translateX(0);
    }
  }

@keyframes sidebar-inside-active {
    from {
        transform: translateX(-100%);
    }
  
    to {
        transform: translateX(0);
    }
  }

.sidebar-inside.active {
    transform: translateX(0);
}

.sidebar-inside-btns {
    background-color: transparent;
    color: #000;
    border-radius: 20px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 12px;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: 350ms;
    text-decoration: none;
}

.sidebar-inside-btns:hover {
    background-color: #c0c0c0;
}

.sidebar-inside-btns.active {
    background-color: #c0c0c0;
}

.sidebar-inside-btns-menu {
    list-style: none;
    margin-top: 20px;
    padding: 20px;
}

.sidebar-inside-btns-content {
    color: #000;
    font-weight: 300;
    margin: 0;
    margin-left: 15px;
}

.page-inside-content {
    z-index: -1;
    animation: opacity 300ms ease-in-out;
    animation-fill-mode: forwards;
    opacity: 0;
}

@-webkit-keyframes opacity {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }

@keyframes opacity {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }
