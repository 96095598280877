:root {
    --main-bg: #f0f0f0;
    --main-color: #fff;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
    background: var(--main-bg);
    font-family: 'Manrope', sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

input, textarea {
    font-family: 'Manrope', sans-serif;
}

.main-container {
    display: flex;
}

.right-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page-content {
    width: 100%;
    display: flex;
}

.page-inside-content {
    padding: 30px;
    width: 100%;
    z-index: 999;
}