.sidebar {
  min-height: 100vh;
  height: 100%;
  max-width: 275px;
  width: 100%;
  background-color: #252a58;
  position: -webkit-sticky;
  z-index: 999;
  position: sticky;
  top: 0;
  padding: 40px;
  padding-top: 5px;
}

.sidebar-acc-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.sidebar-username {
  font-size: 1.075em;
  font-weight: 300;
  color: #fff;
}

.sidebar-username-item {
  text-transform: capitalize;
}

.sidebar-btns-menu {
  color: #fff;
  list-style: none;
  margin-top: 20px;
}

.sidebar-btns {
  background-color: transparent;
  color: #fff;
  border-radius: 20px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
  position: relative;
  user-select: none;
  transition: 350ms;
  text-decoration: none;
}

.sidebar-btns:hover {
  background-color: #0d6efd;
}

.sidebar-btns:active {
  background-color: #1056c0;
}

.sidebar-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar-btns.active {
  background-color: #0d6efd;
}

.sidebar-btns-content {
  color: #fff;
  font-weight: 300;
  margin: 0;
  margin-left: 15px;
}

.sidebar-menu-header {
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
}

.sidebar-logo {
  width: 75px;
  height: 75px;
}

.sidebar-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar-logo-img {
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .sidebar {
    max-width: 100%; /* Full width on mobile */
    padding: 20px; /* Reduced padding */
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
  }

  .sidebar-username {
    font-size: 1em; /* Smaller font size */
  }

  .sidebar-btns {
    padding: 8px 10px; /* Smaller padding for buttons */
    font-size: 0.9em; /* Adjust font size */
  }

  .sidebar-menu-header {
    font-size: 1.1em; /* Smaller header size */
  }

  .sidebar-logo {
    width: 60px; /* Smaller logo size */
    height: 60px;
  }
}
