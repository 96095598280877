.login {
  background: #fff;
  min-height: 100vh;
}

.container-login {
  max-width: 1200px;
  margin: 0 auto;
}

.container-logo {
  padding: 25px;
  text-align: center;
}

.logo {
  text-decoration: none;
}

.container-form {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.l-text {
  color: #0d6efd;
  font-weight: 400;
  margin-bottom: 10px;
}

.l-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

.l-input {
  width: 100%;
  height: 40px;
  color: #000;
  border: none;
  filter: none;
  appearance: none;
  border-bottom: 1px solid #767676;
  margin: 15px;
  font-size: 1.1em;
  outline: none;
  transition: color 0.3s ease, border 0.3s ease;
}

.l-input::placeholder {
  transition: color 0.3s ease;
}

.s-input {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  background-color: #0d6efd;
  color: #fff;
  font-weight: 300;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.s-input:hover {
  background-color: #0a58ca;
  cursor: pointer;
}

.l-input:focus::placeholder {
  color: #0a58ca;
  opacity: 1;
  transition: color 0.3s ease;
}

.l-input:focus {
  border-bottom: 1px solid #0a58ca;
}

.l-img {
  width: 300px;
  margin-left: 100px;
}

.capslock-container {
  background-color: chartreuse;
  color: white;
  transition: 0.3s ease;
  opacity: 0;
}

.capslock-container.active {
  opacity: 1;
}

.l-btn {
  color: #a0a0a0;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9em;
}

.l-btn:hover {
  text-decoration: underline;
}

.l-container-btn {
  margin-top: 5px;
}

.logo-img {
  width: 100px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
}

.checkbox-container-text {
  margin-left: 5px;
  user-select: none;
  font-size: 0.8em;
  color: #555;
  cursor: pointer;
}

.login-error {
  color: red;
  padding: 10px;
}

@media only screen and (max-width: 700px) {
  .container-img {
    display: none;
  }
  input {
    filter: none;
    border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 1;
    outline: 0;
  }
}
