.edit-wrapper {
    display:flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.edit-input-wrapper {
    display:flex;
    flex-flow: row;
    height: 100%;
}
.product-edit-input {
    width: 80%;
    height: 100%;
}
.edit-product-textarea {
    resize:none;
    width: 100%;
    height: 100%;
}
.edit-menu {
    width: 100%;
    display:flex;
    justify-content: space-between;
}
.btn-save {
    width: 100%;
    height: 50px;
    background: red;
}
.file-chooser {
    width: 20%;
    display:flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}
.select-file {
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
}