.home-containers {
  display: flex;
  flex-wrap: wrap;
}

.home-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 150px;
  border-radius: 12px;
  background: #fff;
  padding: 20px;
  margin: 20px 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  cursor: pointer;
  text-decoration: none;
  will-change: contents;
  transition: transform 300ms ease-in-out;
}

.home-btns:hover {
  transform: scale(1.05);
}
