/* AddKilometersForm.css */

.form-container,
.shop-products {
  width: auto;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: Arial, sans-serif;
}

.form-container h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  font-weight: normal;
}

.form-container label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}

.form-container input[type="date"],
.form-container input[type="number"],
.form-container input[type="text"],
.change-password-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
}

.form-container input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}

.form-container input[type="number"]::placeholder,
.form-container input[type="text"]::placeholder {
  color: #aaa;
}

.form-container button {
  width: 100%;
  background-color: #28a745;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #218838;
}

.message {
  text-align: center;
  padding: 20px 20px 0 20px;
}
