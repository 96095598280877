.loading-screen {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-text {
    font-size: 1.5em;
    font-weight: 100;
    letter-spacing: 1px;
    margin-top: 10px ;
    animation: loading-text .8s infinite linear;
}

@keyframes loading-text {
    0% {
        transform: scale(1);
    }
  
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
  }