.shop-products {
  padding: 20px;
}

.shop-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
}

.products-table th,
.products-table td {
  border: 1px solid #dcdcdc;
  padding: 10px;
  text-align: left;
}

.products-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

.products-table td {
  background-color: #ffffff;
  color: #555;
}

.products-table tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.delete-button:hover {
  background-color: #e60000;
}
