.user-table {
    width: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.table {
    width: 100%;
    vertical-align: top;
    caption-side: bottom;
    border-collapse: collapse;
}

thead, tbody, tfoot, tr, td, th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

th, td {
    padding: 1.125em 1.375em;
    font-size: 0.9em;
    font-weight: 300;
    border: none;
    border-bottom: #dee2e6;
}

th {
    font-weight: 600;
}

.btn {
    background-color: #000;
    color: #fff;
    width: 100px;
    height: 35px;
    border: none;
    cursor: pointer;
}

