.navbar {
  width: 100%;
  height: 75px;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 9999;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.navbar-container {
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.navbar-items {
  width: 150px;
  background: #fff;
  padding: 10px;
  height: auto;
  position: fixed;
  top: 78px;
  right: 5px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.navbar-items.active {
  display: flex;
}

.navbar-items-a {
  text-decoration: none;
  color: #333;
}

.navbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ececec;
  transition: background 300ms ease-in-out;
}

.navbar-button:hover {
  background-color: #d8d8d8;
}
